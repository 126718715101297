export default [
  {
    path: '/disapproval-payments',
    name: 'DisapprovalPayments',
    component: () => import('@/views/DisapprovalPayments/Index.vue'),
    meta: {
      pageTitle: 'Ödeme İtirazları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'DISAPPROVAL_PAYMENTS',
      action: 'read',
    },
  },
]
