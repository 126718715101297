export default [
  {
    path: '/current-accounts',
    name: 'CurrentAccounts',
    component: () => import('@/views/CurrentAccounts/Index.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/add/:id_customers',
    name: 'CurrentAccountAdd',
    component: () => import('@/views/CurrentAccounts/Add.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/view/:id',
    name: 'CurrentAccountView',
    component: () => import('@/views/CurrentAccounts/View.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_VIEW',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/edit/:id',
    name: 'CurrentAccountEdit',
    component: () => import('@/views/CurrentAccounts/Edit.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/search-customer',
    name: 'CurrentAccountSearch',
    component: () => import('@/views/CurrentAccounts/Customer-Search.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/addline/:id_current_accounts',
    name: 'CurrentLinesAdd',
    component: () => import('@/views/CurrentAccounts/Lines/Add.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'İşlem Ekle',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/current-accounts/editline/:id',
    name: 'CurrentLinesEdit',
    component: () => import('@/views/CurrentAccounts/Lines/Edit.vue'),
    meta: {
      pageTitle: 'Cari İşlemler',
      breadcrumb: [
        {
          to: '/current-accounts',
          text: 'Liste',
        },
        {
          text: 'İşlem Güncelle',
          active: true,
        },
      ],
      resource: 'CURRENT_ACCOUNTS_EDIT',
      action: 'read',
    },
  },
]
