import moment from 'moment'
import axiosIns from '@/libs/axios'

const ROOT_URL = '/reports/users'
export default {
  namespaced: true,
  state: {
    reportData: {
      charts: {
        yearly: {
          categories: [],
          series: [],
        },
      },
      counters: {
        payments: null,
        pending: null,
        latePayment: null,
      },
      dataList: {
        latestPayments: [],
        latePayments: [],
        pendingPayments: [],
      },
      params: {
        years: {
          dataList: [
            moment().format('YYYY'),
          ],
          selected: moment().format('YYYY'),
        },
      },
    },
    reportParams: {
      id_users: null,
      year: moment().format('YYYY'),
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    loading: false,
  },
  getters: {
    reportData(state) {
      return state.reportData
    },
    reportParams(state) {
      return state.reportParams
    },
    loading(state) {
      return state.loading
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.reportData = data
      if (data.params.years.selected) {
        state.reportParams.year = data.params.years.selected
      }
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    getData({ commit }, params) {
      commit('SET_LOADING', true)
      axiosIns
        .post(ROOT_URL, params)
        .then(response => {
          commit('SET_DATA', response.data.data)
          commit('SET_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
