import axiosIns from '@/libs/axios'

const ROOT_URL = '/PaymentCollections'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    filterData: {
      customer: null,
      id_collection_status: null,
      sdate: null,
      edate: null,
    },
    dataItem: {
      id: null,
      tnumber: null,
      title: null,
      amount: null,
      pdate: null,
      alert1: null,
      alert3: null,
      alert5: null,
      partial: null,
      id_payment_collection_status: null,
      currentAccount: 1, // cari hesap entegrasyon
      id_current_account_lines: null,
      id_customers: null,
      id_users: null,
      submitStatus: false,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    filterData(state) {
      return state.filterData
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_FILTER_DATA(state, data) {
      state.filterData = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        customer: null,
        id_collection_status: null,
        sdate: null,
        edate: null,
      }
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        tnumber: null,
        title: null,
        amount: null,
        pdate: null,
        alert1: null,
        alert3: null,
        alert5: null,
        id_payment_collection_status: null,
        currentAccount: 1, // cari hesap entegrasyon
        id_current_account_lines: null,
        id_customers: null,
        id_users: null,
        submitStatus: false,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'payment_collections.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
