export default [
  {
    path: '/admin/customers',
    name: 'Customers',
    component: () => import('@/views/Customers/Index.vue'),
    meta: {
      pageTitle: 'Müşteri Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CUSTOMER_CARD_LIST',
      action: 'read',
    },
  },
  {
    path: '/admin/customers/add',
    name: 'CustomerAdd',
    component: () => import('@/views/Customers/Add.vue'),
    meta: {
      pageTitle: 'Müşteri Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/customers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'CUSTOMER_CARD_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/customers/edit/:id',
    name: 'CustomerEdit',
    component: () => import('@/views/Customers/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/customers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CUSTOMER_CARD_EDIT',
      action: 'read',
    },
  },
  {
    path: '/admin/customers/view/:id',
    name: 'CustomerView',
    component: () => import('@/views/Customers/View.vue'),
    meta: {
      pageTitle: 'Müşteri Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/customers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CUSTOMER_CARD_VIEW',
      action: 'read',
    },
  },
]
