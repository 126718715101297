import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import smtpConfig from '@/store/core/smtpconfig'
import userTypes from '@/store/core/user_types'
import users from '@/store/core/users'
import payment from '@/store/vpos/payment'
import abilities from '@/store/core/abilities'
import company from '@/store/vpos/company'
import currentAccounts from '@/store/vpos/current_accounts'
import currentAccountLines from '@/store/vpos/current_account_lines'
import disapprovalPayments from '@/store/vpos/disapproval_payments'
import posConfigs from '@/store/vpos/pos_configs'
import banks from '@/store/vpos/banks'
import paymentsHistory from '@/store/vpos/payments_history'
import customers from '@/store/vpos/customers'
import customerAddresses from '@/store/vpos/customer_addresses'
import customerUsers from '@/store/vpos/customer_users'
import customerHistory from '@/store/vpos/customer_history'
import contents from '@/store/core/contents'
import docsUpload from '@/store/vpos/docs_upload'
import announcements from '@/store/vpos/announcements'
import paymentCollections from '@/store/vpos/payment_collections'

// Defines
import cities from '@/store/defines/cities'
import countries from '@/store/defines/countries'
import customerTypes from '@/store/defines/customer_types'
import disapprovalStatuses from '@/store/defines/disapproval_statuses'
import sectors from '@/store/defines/sectors'
import paymentCollectionStatus from '@/store/defines/payment_collection_status'
import paymentStatuses from '@/store/defines/payment_statuses'
// Reports
import reportCurrentStatus from '@/store/reports/current_status'
import reportBanks from '@/store/reports/banks'
import reportCustomers from '@/store/reports/customers'
import reportUsers from '@/store/reports/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    smtpConfig,
    userTypes,
    users,
    payment,
    abilities,
    company,
    posConfigs,
    banks,
    paymentsHistory,
    paymentStatuses,
    customers,
    customerAddresses,
    customerUsers,
    customerHistory,
    contents,
    currentAccounts,
    currentAccountLines,
    docsUpload,
    disapprovalStatuses,
    disapprovalPayments,
    announcements,
    cities,
    countries,
    customerTypes,
    paymentCollections,
    paymentCollectionStatus,
    sectors,
    reportCurrentStatus,
    reportBanks,
    reportCustomers,
    reportUsers,
  },
  strict: process.env.DEV,
})
