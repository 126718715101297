import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    pos_types: [],
    pos_configs: [],
    pos_configsCount: 0,
    pos_config: {
      default: 0,
      status: 0,
      pay3d: 1,
      config: {
        nestpay_client_id: null,
        nestpay_client_api_name: null,
        nestpay_classic_api_password: null,
        nestpay_3d_store_key: null,
        nestpay_api_url: null,
        nestpay_3d_url: null,
        gvp_terminal_id: null,
        gvp_merchant_id: null,
        gvp_user_name: null,
        gvp_provaut_password: null,
        gvp_3d_store_key: null,
        gvp_api_url: null,
        gvp_3d_url: null,
        get724_merchant_id: null,
        get724_user_name: null,
        get724_provaut_password: null,
        get724_3d_store_key: null,
        get724_api_url: null,
        get724_3d_url: null,
        posnet_client_id: null,
        posnet_terminal_id: null,
        posnet_merchant_id: null,
        posnet_encKey: null,
        posnet_api_url: null,
        posnet_3d_url: null,
        payfor_merchant_id: null,
        payfor_merchant_pass: null,
        payfor_usercode: null,
        payfor_userpass: null,
        payfor_api_url: null,
        payfor_3d_url: null,
      },
      installments: [
        {
          text: 'Tek Çekim',
          installment: 0,
          rate: 0,
        },
      ],
      id_pos_types: null,
      id_banks: null,
    },
    pos_config_pay3d: 1,
    pos_installments: [],
    pos_installmentsv2: {
      header: [],
      body: [],
    },
    pos_configSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPosTypes(state) {
      return state.pos_types
    },
    getPos_configs(state) {
      return state.pos_configs
    },
    getPos_configsCount(state) {
      return state.pos_configsCount
    },
    getPos_config(state) {
      return state.pos_config
    },
    getPos_config_pay3d(state) {
      return state.pos_config_pay3d
    },
    getPos_configSaveStatus(state) {
      return state.pos_configSave
    },
    getInstallments(state) {
      return state.pos_installments
    },
    getInstallmentsV2(state) {
      return state.pos_installmentsv2
    },
  },
  mutations: {
    POS_TYPES(state, data) {
      state.pos_types = data
    },
    POS_CONFIGS_LIST(state, data) {
      state.pos_configs = data
    },
    POS_CONFIG_VIEW(state, data) {
      state.pos_config = data
    },
    POS_CONFIG_PAY3D(state, data) {
      state.pos_config_pay3d = data
    },
    POS_CONFIGS_COUNT(state, data) {
      state.pos_configsCount = data
    },
    POS_CONFIG_SAVE(state, data) {
      state.pos_configSave = data
    },
    POS_INSTALLMENTS(state, data) {
      state.pos_installments = data
    },
    POS_INSTALLMENTS_V2(state, data) {
      state.pos_installmentsv2 = data
    },
  },
  actions: {
    pos_types({ commit }) {
      axiosIns
        .post('/pos_types')
        .then(response => {
          commit('POS_TYPES', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pos_installments({ commit }) {
      axiosIns
        .post('/pos_configs/installments')
        .then(response => {
          commit('POS_INSTALLMENTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pos_installments2({ commit }) {
      axiosIns
        .post('/pos_configs/installmentsv2')
        .then(response => {
          commit('POS_INSTALLMENTS_V2', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pos_configsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/pos_configs', data)
        .then(response => {
          commit('POS_CONFIGS_LIST', response.data.data)
          commit('POS_CONFIGS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    pos_configView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'pos_configs.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/pos_configs', data)
        .then(response => {
          commit('POS_CONFIG_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pos_configPay3d({ commit }, id) {
      const data = JSON.stringify({
        select: [
          'pos_configs.id AS id',
          'pos_configs.pay3d AS pay3d',
        ],
        where: {
          'pos_configs.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/pos_configs', data)
        .then(response => {
          commit('POS_CONFIG_PAY3D', response.data.data.pay3d)
        })
        .catch(error => console.error(error))
    },
    pos_configSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/pos_configs/saveData', data)
        .then(response => {
          commit('POS_CONFIG_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
