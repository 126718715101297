export default [
  {
    path: '/reports/current-status',
    name: 'CurrentStatus',
    component: () => import('@/views/Reports/CurrentStatus/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Güncel Durum',
          active: true,
        },
      ],
      resource: 'REPORTS_CURRENT_STATUS',
      action: 'read',
    },
  },
  {
    path: '/reports/banks',
    name: 'BanksReport',
    component: () => import('@/views/Reports/Banks/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Bankalar',
          active: true,
        },
      ],
      resource: 'REPORTS_BANKS',
      action: 'read',
    },
  },
  {
    path: '/reports/customers',
    name: 'CustomersReport',
    component: () => import('@/views/Reports/Customers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteriler',
          active: true,
        },
      ],
      resource: 'REPORTS_CUSTOMERS',
      action: 'read',
    },
  },
  {
    path: '/reports/users',
    name: 'UsersReport',
    component: () => import('@/views/Reports/Users/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Temsilciler',
          active: true,
        },
      ],
      resource: 'REPORTS_USERS',
      action: 'read',
    },
  },
]
