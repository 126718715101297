export default [
  {
    path: '/payment-collections',
    name: 'PaymentCollections',
    component: () => import('@/views/PaymentCollections/Index.vue'),
    meta: {
      pageTitle: 'Ödeme Takvimi',
      breadcrumb: [
        {
          text: 'Ödeme Takvimi',
          active: true,
        },
      ],
      resource: 'PAYMENT_COLLECTION_LIST',
      action: 'read',
    },
  },
  {
    path: '/payment-collections/customer-search',
    name: 'PaymentCollectionSearch',
    component: () => import('@/views/PaymentCollections/Customer-Search.vue'),
    meta: {
      pageTitle: 'Ödeme Takvimi',
      breadcrumb: [
        {
          to: '/payment-collections',
          text: 'Ödeme Takvimi',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'PAYMENT_COLLECTION_ADD',
      action: 'read',
    },
  },
  {
    path: '/payment-collections/add/:id_customers',
    name: 'PaymentCollectionAdd',
    component: () => import('@/views/PaymentCollections/Add.vue'),
    meta: {
      pageTitle: 'Ödeme Takvimi',
      breadcrumb: [
        {
          to: '/payment-collections',
          text: 'Ödeme Takvimi',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'PAYMENT_COLLECTION_ADD',
      action: 'read',
    },
  },
  {
    path: '/payment-collections/edit/:id',
    name: 'PaymentCollectionEdit',
    component: () => import('@/views/PaymentCollections/Edit.vue'),
    meta: {
      pageTitle: 'Ödeme Takvimi',
      breadcrumb: [
        {
          to: '/payment-collections',
          text: 'Ödeme Takvimi',
          active: false,
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'PAYMENT_COLLECTION_EDIT',
      action: 'read',
    },
  },
]
