import axiosIns from '@/libs/axios'

const ROOT_URL = '/customer_history'
export default {
  namespaced: true,
  state: {
    dataList: {
      lastPayments: [],
      overdues: [],
      outstanding: [],
    },
    currentAccounts: {
      id: null,
    },
    counters: {
      payments: 0,
      latePayment: 0,
      pending: 0,
    },
    loadingData: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    currentAccounts(state) {
      return state.currentAccounts
    },
    dataLoading(state) {
      return state.loadingData
    },
    counters(state) {
      return state.counters
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_CURRENT_ACCOUNT_LIST(state, data) {
      state.currentAccounts = data
    },
    SET_DATA_COUNTERS(state, data) {
      state.counters = data
    },
    SET_LOADING_DATA(state, data) {
      state.loadingData = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_LOADING_DATA', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data.payments)
          commit('SET_CURRENT_ACCOUNT_LIST', response.data.data.currentAccounts)
          commit('SET_DATA_COUNTERS', response.data.data.payments.counters)
          commit('SET_LOADING_DATA', false)
        })
        .catch(error => console.error(error))
    },
  },
}
